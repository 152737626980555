.header {
    background-color: #fafafa;
    padding: 1rem;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    height: 3rem;
}

.nav {
    display: flex;
    gap: 1rem;
}

.nav-link {
    text-decoration: none;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    color: #5c4bce;
}

.info-icon,
.chat-icon {
    margin-left: 0.5rem;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

@media (max-width: 768px) {
    .nav-open {
        display: flex;
    }

    .menu-toggle {
        display: block;
    }
}

@media screen and (max-width: 460px) {
    .logo {
        height: 28px;
    }
}
html.no-touch-device button#nav-toggle:hover {
    background-color: #ededed;
}
button#nav-toggle {
    border: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition-property: background-color;
    transition-duration: 150ms;
    background-color: #f2f2f2;
    flex-shrink: 0;
    cursor: pointer;
    display: none;
}
button#nav-toggle.open > svg.hamburger-icon .top {
    animation-name: make-x-top;
}
button#nav-toggle svg.hamburger-icon .burger-line {
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    stroke: #5c4bce;
    fill: none;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 3.5px;
    transform-origin: center;
}
button#nav-toggle.open > svg.hamburger-icon .bottom {
    animation-name: make-x-bottom;
}

@keyframes make-x-bottom {
    0% {
        transform: rotate(0deg) translateY(0);
    }
    50% {
        transform: rotate(0deg) translateY(-10%);
    }
    100% {
        transform: rotate(-45deg) translateY(-10%);
    }
}

@keyframes make-x-top {
    0% {
        transform: rotate(0deg) translateY(0);
    }
    50% {
        transform: rotate(0deg) translateY(10%);
    }
    100% {
        transform: rotate(45deg) translateY(10%);
    }
}

.dropdown {
    flex-direction: column;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition:
        max-height 0.6s ease-in-out,
        opacity 0.3s ease-out;
    opacity: 0;
    order: 3;
}
.dropdown.open {
    max-height: 300px; /* Adjust this value based on your content */
    opacity: 1;
}
@media screen and (max-width: 768px) {
    button#nav-toggle {
        display: block;
    }
}

.term-link {
    text-decoration: none;
    box-shadow: inset 0px -0.15em 0px 0px #a9fae5;
    color: #5c4bce;
    font-weight: 600;
    transition-duration: 150ms;
    transition-timing-function: ease-out;
    padding: 5px;
}
.term-link:hover {
    color: #000000;
    box-shadow: inset 0px -1.15em 0px 0px rgba(169, 250, 229, 0.6);
}
