.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.documentation-content {
    flex: 1;
    max-width: 1200px;
    margin: 2rem auto 0;
    padding: 2rem;
}

.documentation-title {
    font-size: 2.5rem;
    color: #1f2937;
    margin-bottom: 3rem;
    text-align: center;
}

.documentation-list {
    max-width: 800px;
    margin: 0 auto;
}

.documentation-item {
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 2rem;
    transition:
        transform 0.2s ease,
        box-shadow 0.2s ease;
}

.documentation-item:hover {
    transform: translateY(-4px);
    box-shadow:
        0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.documentation-item h2 {
    font-size: 1.5rem;
    color: #374151;
    margin-bottom: 1rem;
}

.documentation-item p {
    color: #6b7280;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.documentation-link {
    display: inline-block;
    color: #6366f1;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
}

.documentation-link:hover {
    color: #4f46e5;
}

@media (max-width: 768px) {
    .documentation-content {
        padding: 1rem;
        margin-top: 60px;
    }

    .documentation-title {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .documentation-item {
        padding: 1.5rem;
    }
}
