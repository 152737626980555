.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}

.hero {
    text-align: center;
    max-width: 1200px;
}

.hero h1 {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 2rem;
    line-height: 1.2;
}

.hero h1::after {
    content: '';
    display: block;
    width: 180px;
    height: 10px;
    background-color: #a78bfa;
    margin: 0.5rem auto 0;
}

.report-container {
    height: 700px;
    width: 40%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.report-image {
    max-width: 450px;
    height: auto;
    margin-top: 2rem;
}

.term-modal-image {
    width: 220px;
    bottom: 0;
    border-radius: 15px;
    margin-top: -500px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(0.6);
}
.term-modal-image.visible {
    opacity: 1;
    transform: scale(1);
}

@media screen and (max-width: 768px) {
    .hero {
        width: 100%;
    }
    .report-container {
        height: 700px;
    }
    .hero h1 {
        font-size: 3rem;
    }
}

.features {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    padding: 4rem 2rem;
}
.features h1 {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 2rem;
    line-height: 1.2;
    text-align: center;
}

.how-it-works {
    background-color: #fafafa;
    padding: 4rem 2rem;
}

.how-it-works-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
}

.how-it-works-image {
    flex: 1;
    margin-right: 4rem;
}

.how-it-works-image img {
    width: 100%;
    border-radius: 50%;
    border: 2px solid #a6a6a6;
    left: 0;
    max-width: 450px;
}

.how-it-works-text {
    flex: 1;
}

.how-it-works h2 {
    color: #6366f1;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.how-it-works h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.how-it-works p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
    line-height: 1.6;
}

.sample-report-button {
    background-color: #6366f1;
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sample-report-button:hover {
    background-color: #4f46e5;
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .how-it-works {
        padding: 2rem 2rem;
    }

    .how-it-works-content {
        flex-direction: column;
    }

    .how-it-works-image {
        margin-right: 0;
        margin-bottom: 2rem;
    }
}

@media (max-width: 480px) {
    .how-it-works h3 {
        font-size: 1.5rem;
    }

    .how-it-works p {
        font-size: 0.9rem;
    }
}

.benefits-for-physicians {
    background-color: #fafafa;
    padding: 4rem 2rem;
}

.benefits-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
}

.benefits-text {
    flex: 1;
}

.benefits-text h2 {
    color: #6366f1;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.benefits-text h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.benefits-text p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

.benefits-image {
    flex: 1;
    position: relative;
    margin-right: 2rem;
}

.benefits-image img {
    width: 100%;
    border-radius: 50%;
    border: 2px solid #a6a6a6;
    left: 0;
    max-width: 450px;
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .benefits-image {
        margin: 0;
    }
    .benefits-for-physicians {
        padding: 2rem;
    }
    .benefits-for-physicians > .benefits-content {
        flex-direction: column-reverse;
    }

    .benefits-text {
        padding-right: 0;
        margin-bottom: 2rem;
    }
}

@media (max-width: 480px) {
    .benefits-text h3 {
        font-size: 2rem;
    }

    .benefits-text p {
        font-size: 0.9rem;
    }
}

.benefits-for-health-systems {
    background-color: #fafafa;
    padding: 4rem 2rem;
}

.benefits-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
}

.benefits-image {
    flex: 1;
    position: relative;
    margin-right: 4rem;
}

.benefits-image img {
    width: 100%;
    max-width: 450px;
    border-radius: 50%;
}

.benefits-text {
    flex: 1;
}

.benefits-text h2 {
    color: #6366f1;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.benefits-text h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.benefits-text p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.book-demo-button {
    background-color: #6366f1;
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.book-demo-button:hover {
    background-color: #4f46e5;
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .benefits-image {
        margin: 0;
    }
    .benefits-for-health-systems {
        padding: 2rem;
    }
    .benefits-content {
        flex-direction: column;
    }

    .benefits-image {
        margin-bottom: 2rem;
    }
}

@media (max-width: 480px) {
    .benefits-text h3 {
        font-size: 2rem;
    }

    .benefits-text p {
        font-size: 0.9rem;
    }
}

.sd-section {
    background: linear-gradient(300deg, rgb(138, 123, 234) 0%, rgb(97, 87, 189) 55%, rgb(75, 68, 165) 91%);
    color: white;
    padding: 4rem 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
}

.sd-content {
    max-width: 55%;
    z-index: 1;
}

.sd-subtitle {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #a5f3fc;
    font-weight: normal;
}

.sd-title {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.sd-highlight {
    color: #a5f3fc;
}

.sd-description {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    line-height: 1.6;
    max-width: 90%;
}

.sd-try-button {
    background-color: #a5f3fc;
    color: #6366f1;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sd-try-button:hover {
    background-color: #7dd3fc;
}

.sd-phone-image {
    position: absolute;
    right: -5%;
    top: 50%;
    transform: translateY(-50%);
    width: 45%;
    max-width: 500px;
}

.sd-phone-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

@media (max-width: 1200px) {
    .sd-section {
        padding: 4rem 3rem;
    }

    .sd-content {
        max-width: 60%;
    }

    .sd-phone-image {
        right: -10%;
        width: 50%;
    }
}

@media (max-width: 768px) {
    .sd-section {
        flex-direction: column;
        padding: 4rem 2rem;
    }

    .sd-content {
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .sd-description {
        max-width: 100%;
    }

    .sd-phone-image {
        position: relative;
        right: auto;
        top: auto;
        transform: none;
        width: 80%;
        margin: 2rem auto 0;
    }
    .sd-try-button {
        width: 90%;
        margin-left: 5%;
    }
}

@media (max-width: 480px) {
    .sd-title {
        font-size: 2.5rem;
    }

    .sd-description {
        font-size: 1rem;
    }
}

.footer {
    background-color: #f9fafb;
    padding: 3rem 0;
    text-align: center;
}

.footer-title {
    font-size: 1.5rem;
    color: #4b5563;
    margin-bottom: 0.5rem;
}

.paper-plane {
    display: inline-block;
    transform: rotate(45deg);
    margin-left: 0.5rem;
}

.footer-email {
    font-size: 2rem;
    color: #6366f1;
    text-decoration: none;
    border-bottom: 2px solid #a5f3fc;
    transition: border-color 0.3s ease;
    display: inline-block;
    margin-bottom: 2rem;
}

.footer-email:hover {
    border-color: #6366f1;
}

.footer-info {
    margin: 0 auto 2rem;
    padding: 4rem 1rem 0rem 1rem;
}

.footer-info p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.footer-info a {
    color: #6366f1;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-info a:hover {
    color: #4f46e5;
}

.footer-bottom {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: #6b7280;
}

@media (max-width: 768px) {
    .footer {
        padding: 2rem 1rem;
    }

    .footer-title {
        font-size: 1.25rem;
    }

    .footer-email {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .footer-info {
        font-size: 0.9rem;
    }
}

.paper-plane {
    display: inline-block;
    transform: rotate(45deg);
    margin-left: 0.5rem;
}

.footer-nav {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.footer-link {
    color: #6b7280;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-link:hover {
    color: #4b5563;
}

@media (max-width: 640px) {
    .footer {
        padding: 2rem 1rem;
    }

    .footer-title {
        font-size: 1.25rem;
    }

    .footer-email {
        font-size: 1rem;
    }

    .footer-bottom {
        font-size: 0.75rem;
    }

    .footer-nav {
        flex-direction: column;
        gap: 0.5rem;
    }
}

.report-sample-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sample-report-button.big {
    font-size: 1.5rem;
    padding: 20px 40px;
    z-index: 999;
}

@media screen and (max-width: 768px) {
    .sample-report-button.big {
        font-size: 1rem;
    }
    .features h1 {
        font-size: 3rem;
    }
}

@media screen and (max-width: 580px) {
    .features h1 {
        font-size: 2rem;
    }
    .report-sample-button-container {
        display: block;
    }
    .sample-report-button.big {
        margin: 0;
    }
    .report-image {
        width: 350px;
    }
    .term-modal-image {
        width: 180px;
        margin-top: -390px;
    }
    .report-container {
        height: 520px;
        width: 100%;
    }
}
